import React, { Component } from "react";
import Black_500_500 from "../../static/images/500x500.jpg";
import Profile from "../../static/images/Profile.jpg";

export default class Home extends Component {
    render() {
        return (
            <div className="arlo_tm_section" id="home">
                <div className="arlo_tm_hero_header particle jarallax" data-speed="0.2">
                    <div className="overlay"></div>
                    {this.renderHomeContent()}
                    {this.renderArrow()}
                </div>
            </div>
        );
    }

    renderHomeContent() {
        return (
            <div className="hero_content">
                {this.renderImage()}
                {this.renderName()}
                {this.renderTextTyping()}
            </div>
        );
    }

    renderImage() {
        return (
            <div className="image_wrap">
                <img src={Black_500_500} alt="" />
                <div className="main" data-img-url={Profile}></div>
            </div>
        );
    }

    renderName() {
        return (
            <div className="name_holder">
                <h3>
                    Shlok <span>Gondalia</span>
                </h3>
            </div>
        );
    }

    renderTextTyping() {
        return (
            <div className="text_typing">
                <p>
                    I'm{" "}
                    <span
                        className="arlo_tm_animation_text_word"
                        style={{ color: "#ff4b36" }}
                    ></span>
                </p>
            </div>
        );
    }

    renderArrow() {
        return (
            <div className="arlo_tm_arrow_wrap bounce anchor">
                <a href="#about">
                    <i className="fas fa-angle-double-down"></i>
                </a>
            </div>
        );
    }
}
