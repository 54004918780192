import React, {Component} from 'react';
import {fontAwesome, iconify} from '../CommonFunctions'
import { Button } from '@mui/material';

export default class Technologies extends Component {

    constructor(props) {
        super(props);
        this.state = {
            extraTechnologiesOpen: false
        };
    }

    render() {
        return(
            <li className="wow fadeInLeft" data-wow-duration="0.8s">
                <div className="inner">
                    <div className="skillheading">
                        <h1><span>Technologies</span></h1>
                    </div>
                    <div className="title">
                    </div>
                    <div className="text" style={{textAlign: "center"}}>
                        {iconify("fontisto:aws", "AWS")}
                        {fontAwesome("languages fab fa-react fa-4x fa-fw", "React")}
                        {iconify("simple-icons:gradle", "Gradle")}
                        {this.state.extraTechnologiesOpen && this.renderExtraTechnologies()}
                        <Button style={{height: "75px", width: "75px", display: "inline-flex", alignItems: "flex-end"}} onClick={() => this.setState(prevState => ({extraTechnologiesOpen: !prevState.extraTechnologiesOpen}))}>
                                {!this.state.extraTechnologiesOpen ? <i className="arrow_bounce fas fa-angle-double-down fa-2x"></i> : <i className="arrow_bounce fas fa-angle-double-up fa-2x"></i>}
                        </Button>
                    </div>
                </div>
            </li>
        )
    }

    renderExtraTechnologies() {
        return (
            <div>
                {iconify("dashicons:rest-api", "Restful API")}
                {fontAwesome("languages fab fa-docker fa-4x fa-fw", "Docker")}
                {iconify("file-icons:jest", "Jest")}
                {iconify("mdi:numeric-5-circle", "JUnit")}
                {fontAwesome("languages fas fa-feather fa-4x fa-fw", "Maven")}
                {fontAwesome("languages fab fa-node-js fa-4x fa-fw", "Node.js")}
                {iconify("file-icons:webpack", "Webpack")}
                {iconify("cib:json", "JSON")}
                {fontAwesome("languages fab fa-bootstrap fa-4x fa-fw", "Bootstrap")}
                {iconify("file-icons:leaflet", "Leaflet")}
                {iconify("cib:svg", "SVG")}
                {iconify("simple-icons:googleearth", "Google KML")}
                {iconify("simple-icons:microsoftsqlserver", "Microsoft SQL Server")}
                {iconify("whh:mysqltwo", "MySQL")}
                {iconify("ph:stack-bold", "Google SQL")}
                {iconify("whh:pgsql", "pgsql")}
                {fontAwesome("languages fas fa-terminal fa-4x fa-fw", "Tmux")}
                {iconify("devicon-plain:apachespark-wordmark", "Spark")}
            </div>
        )
    }
}